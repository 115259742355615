
















































/* eslint-disable */
import Vue from "vue";
export default Vue.extend({
  name: "Nav",
  data: () => ({
    expand: false,
    items: [
      { title: "Dashboard", icon: "mdi-view-dashboard", link: "/" },

      {
        title: "Vendas",
        icon: "mdi-sale",
        link: "/base/sales/home",
      },
      {
        title: "Extrato Conta",
        icon: "mdi-finance",
        link: "/finance/account-reports",
      },
      {
        title: "Transações",
        icon: "mdi-finance",
        link: "/finance/movements",
      },
      {
        title: "Contas a Receber",
        icon: "mdi-currency-usd",
        link: "/finance/report/account-receivables",
      },

      {
        title: "Contas a Pagar",
        icon: "mdi-currency-usd-off",
        link: "/finance/report/account-payables",
      },
      {
        title: "Estoque",
        icon: "mdi-form-select",
        link: "/base/stock-transactions",
      },
      {
        title: "Clientes",
        icon: "mdi-card-account-details-outline",
        link: "/base/customers",
      },
      {
        title: "Funcionários",
        icon: "mdi-account-group",
        link: "/base/employees",
      },
      {
        title: "Produtos",
        icon: "mdi-food",
        link: "/base/products",
      },
      {
        title: "Fornecedores",
        icon: "mdi-office-building-marker",
        link: "/base/suppliers",
      },
    ],
  }),
  mounted() {
    this.$root.$on("changeDrawe", () => {
      this.expand = !this.expand;
    });
    const us = localStorage.getItem("user");
    if (us && typeof us === "string") {
      this.expand = JSON.parse(us).visibleMenu;
    }
  },
});
